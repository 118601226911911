import React from "react";

const Header = () => (

	<React.Fragment>

		<header className="header">
			<div className="header-box">

				<h1><b>5</b>-a-side team picker</h1>
				 
			</div>	

		</header>

	</React.Fragment>


);

export default Header; 